<template>
    <span>
        <div class="ol-dashboard__account">
            <div class="ol-dashboard__account-item">
                <h4 class="ol-dashboard__container-title">Ubah Kata Sandi</h4>
                <p class="ol-dashboard__account">Perbarui kata sandi Anda untuk mengontrol, melindungi dan mengamankan akun.</p>
                <alert-error v-if="message.error != null" :pesan="message.error"/>
                <alert-success v-if="message.ok != null" :pesan="message.ok"></alert-success>
                <div class="add-fix">
                    <div  v-if="user.have_password" class="ol-input-box ol-input-box--half">
                        <label class="ol-input-label">Kata Sandi Saat Ini</label>
                        <div class="ol-input-ico" >
                            <Password v-model="form.last_password" :toggle="true" :showStrengthMeter="false" :badge="false" />
                        </div>
                    </div>
                    <div v-if="user.have_password" class="ol-input-box ol-input-box--half">
                        &nbsp;
                    </div>

                    <div class="ol-input-box ol-input-box--half">
                        <label class="ol-input-label">Kata Sandi Baru</label>
                        <div class="ol-input-ico">
                            <Password v-model="form.new_password" :badge="false" :toggle="true" @score="showScore" @feedback="showFeedback" />
                        </div>
                    </div>
                    <div class="ol-input-box ol-input-box--half">
                        <label class="ol-input-label">Konfirmasi Kata Sandi</label>
                        <div class="ol-input-ico">
                            <Password v-model="form.confirm_password" :toggle="true" :showStrengthMeter="false" :badge="false" />
                        </div>
                    </div>
                    <div class="ol-input-box ol-input-box--full">
                        <button type="button" name="" class="ol-btn" @click="save()">Simpan</button>
                    </div>
                </div>


            </div>
        </div>
        <LoadingImg v-if="loading" />
    </span>
</template>

<script>
import Password from 'vue-password-strength-meter'
import globalUrl from '@/util/globallUrl'
import {mapGetters} from "vuex";
const URL = globalUrl.WS_PROFILE.PASSWORD;

export default {
    name:"ChangePassword",
    components:{
        Password,
    },
    computed:{
        ...mapGetters('auth', ['user']),
    },
    data(){
        return {
            form:{
                last_password: null,
                new_password: null,
                confirm_password: null,
            },
            loading:false,
            message:{
                ok:null,
                error:null,
            },
            score:0,
        }
    },
    methods:{
        showFeedback ({suggestions, warning}) {
            // console.log('🙏', suggestions)
            // console.log('⚠', warning)
        },
        resetForm(){
            this.form.last_password = null;
            this.form.new_password = null;
            this.form.confirm_password = null;
        },
        showScore(score){
            this.score = score;
            // console.log(score);
        },
        async save(){
            this.message.error=null;
            this.message.ok=null;

            if(this.user.have_password && !this.form.last_password){
                this.message.error="Kata sandi saat ini wajib dilengkapi.";
            }else if(!this.form.new_password){
                this.message.error="Kata sandi baru wajib dilengkapi.";
            }else if(!this.form.confirm_password){
                this.message.error="Konfirmasi Kata sandi wajib dilengkapi.";
            }else{
                if(this.score >= 1){
                    if(this.form.new_password === this.form.confirm_password){
                        this.loading = true;
                        await URL.UPDATE(this.form).then(response => {
                            let result = response.data;
                            this.message.ok=result.data;
                            this.loading = false;
                            this.resetForm();
                            this.$store.dispatch("auth/havePassword");
                        }).catch(error => {
                            let result = error.response.data;
                            // console.log("save", result);
                            this.message.error= result.data.password[0];
                            this.loading = false;
                        });
                    }else{
                        this.message.error="Gagal Konfirmasi kata sandi, periksa kembali kata sandi Anda.";
                    }
                }else{
                    this.message.error="Kekuatan kata sandi minimal medium (memenuhi 2 bar)";
                }
            }
        }
    },
    mounted(){
        // console.log('have_password',this.user.have_password);
    }
}
</script>

<style>

</style>
